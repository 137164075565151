<template>
  <v-navigation-drawer
    v-model="localDrawer"
    :height="isMobile ? 'auto' : '100%'"
    fixed
    :bottom="isMobile"
    :right="!isMobile"
    temporary
    color="background"
  >
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="d-inline-flex"
              ><span class="d-flex align-center"
                >{{ $t("app.hello") }} {{ user.firstName }} {{ user.lastName }}</span
              >
              <v-spacer></v-spacer
              ><span class="d-flex"
                ><v-btn icon :to="{ name: 'edit-profile' }" text
                  ><v-icon>mdi-pencil-box-outline</v-icon></v-btn
                ></span
              ></v-list-item-title
            >
            <v-divider class="my-4"></v-divider>
            <v-list-item-subtitle class="my-1"
              ><v-icon color="yellow darken-3" left>mdi-star</v-icon>
              {{ $t("navigation.availablePoints") }} :
              <span class="font-weight-bold">{{ formatNumber(user.availablePoints) || "/" }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1"
              ><v-icon color="success" left>mdi-star</v-icon> {{ $t("navigation.earnedPoints") }} :
              <span class="font-weight-bold">{{ formatNumber(user.earnedPoints) || "/" }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1"
              ><v-icon color="info" left>mdi-star</v-icon> {{ $t("navigation.reservedPoints") }} :
              <span class="font-weight-bold">{{ formatNumber(user.reservedPoints) || "/" }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1"
              ><v-icon color="error" left>mdi-star</v-icon> {{ $t("navigation.spentPoints") }} :
              <span class="font-weight-bold">{{ formatNumber(user.spentPoints) || "/" }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="user.city" class="my-1"
              ><v-icon left>mdi-city</v-icon>
              {{ user?.city?.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="user.shop" class="my-1"
              ><v-icon color="teal" left>mdi-briefcase-variant</v-icon>
              {{ user?.shop?.address }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item dense link :to="{ name: 'my-sales' }">
          <v-list-item-icon>
            <v-icon>mdi-sale</v-icon>
          </v-list-item-icon>
          {{ $t("app.mySales") }}
        </v-list-item>
        <v-list-item dense link :to="{ name: 'my-purchases' }">
          <v-list-item-icon>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-icon>
          {{ $t("app.myPurchases") }}
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item dense link :to="{ name: 'logout' }">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          {{ $t("app.logout") }}
        </v-list-item>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    user() {
      return this.$auth.user() || {};
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        Event.$emit("app-drawer-update", value);
      }
    }
  }
};
</script>
<style scoped>
.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 70% !important;
}
</style>
